/** 
 * main.css
 * 
 * - Constants
 * - General HTML tags
 * - Helper classes
 * - Page layout
 * - RichText-Editor
 * - Accessibility helpers
 * - Responsive-related stuff (imported)
 * 
 */

/* === Constants =================================================== */

$image-dir: "../img/";

$page-width: 1005px;

$image-margin: 16px;

$color-black: #000000;
$color-blue: #00A4CD;
$color-light-blue: #d3e7f4;
$color-dark-gray: #666666;
$color-light-gray: #edeceb;
$color-medium-gray: #999999;
$color-orange: #ff7900;
$color-white: #ffffff;

$default-font-family: Verdana,'Lucida Grande',Arial,sans-serif;
$default-text-color: $color-black;
$default-font-size: 14px;

$text-line-height: 1.5em;

$h1-font-family: $default-font-family;
$h1-font-size: 2em;
$h1-font-weight: normal;
$h1-text-color: $default-text-color;

$h2-font-family: $default-font-family;
$h2-font-size: 1.17em;
$h2-font-weight: normal;
$h2-text-color: $default-text-color;

$h3-font-family: $default-font-family;
$h3-font-size: 1em;
$h3-font-weight: normal;
$h3-text-color: $default-text-color;

$link-color: $color-blue;
$link-font-weight: normal;
$link-text-decoration: none;
$link-hover-color: $color-black;
$link-hover-text-decoration: underline;

$input-text-color: #494949;
$input-font-family: $default-font-family;

$body-background-color: $color-light-gray;

$header-background-image: $image-dir + "/background-header.png";

$topnav-font-size: 1.3em;
$topnav-link-font-weight: normal;
$topnav-link-color: $color-orange;
$topnav-link-text-decoration: none;
$topnav-link-hover-color: $color-orange;
$topnav-link-hover-text-decoration: underline;
$topnav-current-link-font-weight: normal;
$topnav-current-link-color: $color-black;
$topnav-current-link-text-decoration: none;
$topnav-current-link-hover-color: $color-black;
$topnav-current-link-hover-text-decoration: underline;

$title-font-size: 45px;
$title-text-color: $default-text-color;
$title-link-font-weight: normal;
$title-link-color: $default-text-color;
$title-link-text-decoration: none;
$title-link-hover-color: $default-text-color;
$title-link-hover-text-decoration: underline;

$slogan-font-size: $default-font-size;
$slogan-text-color: $default-text-color;

$sidebar-font-size: 13px;
$sidebar-h2-font-size: 15px;
$sidebar-h3-font-size: 15px;

$event-h1-font-size: 160%;
$event-subtitle-font-size: 1.17em;

$footer-border-color: $color-medium-gray;


/* === General HTML tags =========================================== */

* {
	box-sizing: border-box; 
}
html  {
	height: 100%;
}

body {
	margin: 0 auto; 
	padding: 0;
	height: 100%;
	background-color: $body-background-color;
	background-image: url($header-background-image);
	background-repeat: repeat-x;
	color: $default-text-color; 
	font-family: $default-font-family; 
	font-size: $default-font-size;
}

p, ul, ol, li, table {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
	line-height: $text-line-height;
}

h1, h2, h3, h4, h5, h6 {
    padding-top: 0;
    padding-bottom: 0;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
    margin-top: 0;
}

h1 {
	padding-bottom: 6px;
	font-family: $h1-font-family;
	font-size: $h1-font-size;
	font-weight: $h1-font-weight;
	color: $h1-text-color;
}

h2 {
	font-family: $h2-font-family;
	font-size: $h2-font-size;
	font-weight: $h2-font-weight;
	color: $h2-text-color;
}

h3 {
	font-family: $h3-font-family;
	font-size: $h3-font-size;
	font-weight: $h3-font-weight;
	color: $h3-text-color;
}

a img {
    border: 0;
}

a, a:visited {
	color: $link-color;
	text-decoration: $link-text-decoration;
	font-weight: $link-font-weight;
}

a:hover, a:active {
	color: $link-hover-color;
	text-decoration: $link-hover-text-decoration;
}

pre {
	font-size: $default-font-size * 0.7;
	line-height: 1.2;
}

input[type=text], input[type=password], textarea {
	color: $input-text-color; 
	font-family: $input-font-family;
}

input, button, select, option, .button {
	color: $input-text-color;	
	font-family: $input-font-family;
}

input[type=submit], input[type=button], button, .button {
	padding: 0;
}

/* === Helper classes ============================================== */

/* Ensured the container is at least as high as
 * any floats it might contain.
 */
.container {
    overflow: hidden;
}
.container:after {
    content: "";
    display: table;
    clear: both;
}

/* === Page layout ================================================= */

#overlay {
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    z-index: 500;
    background-color: rgba(1, 1, 1, 0.75);
    left: 0;
}

#page_wrapper {
	min-height: 100vh;
}

#page {
	margin: 0 auto 0;
	width: $page-width;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: auto auto 1fr auto;
	grid-template-areas:
		"header header"
	    "topnav topnav" 
		"main   aside"
		"footer footer";
}

/* --- Header ------------------------------------------------------ */

header {
	padding: 23px 12px 0 20px;
	height: 153px;
	grid-area: header;
	display: grid;
	grid-template-columns: min-content 1fr min-content;
	grid-template-rows: min-content 1fr;
	grid-template-areas: 
		"logo title  hamburger"
		"logo slogan hamburger";
}

header a, header a:visited {
	color: $title-link-color;
	text-decoration: $title-link-text-decoration;
}
header a:hover, header a:active {
	color: $title-link-hover-color;
	text-decoration: $title-link-hover-text-decoration;
}

#logo {
	grid-area: logo;
	margin-left: 2px;
	margin-right: 33px;
}

#title {
	grid-area: title;
	margin-top: 3px;
	font-size: $title-font-size;
	color: $title-text-color;
}

#slogan {
	grid-area: slogan;
	margin-top: 3px;
	font-size: $slogan-font-size;
	color: $slogan-text-color;
}

#hamburger {
	grid-area: hamburger;
}


/* --- Top menu ---------------------------------------------------- */

#topnav {
	grid-area: topnav;
	padding: 10px 0 0 18px;
	height: 40px;
	font-size: $topnav-font-size;
}

#topnav ul {
	display: flex;
	flex-flow: row wrap;
	margin: 0;
	padding: 0;
	list-style: none;
}

#topnav li {
	margin-right: 26px;
}

#topnav li a, #topnav li a:visited {
	color: $topnav-link-color;
	font-weight: $topnav-link-font-weight;
	text-decoration: $topnav-link-text-decoration;
}
#topnav li a:hover, #topnav li a:active {
	color: $topnav-link-hover-color;
	text-decoration: $topnav-link-hover-text-decoration;
}
#topnav li.current a, #topnav li.current a:visited {
	color: $topnav-current-link-color;
	font-weight: $topnav-current-link-font-weight;
	text-decoration: $topnav-current-link-text-decoration;
}
#topnav li.current a:hover, #topnav li.current a:active {
	color: $topnav-current-link-hover-color;
	text-decoration: $topnav-current-link-hover-text-decoration;
}


/* --- Main content ------------------------------------------------ */

main {
	grid-area: main;
	padding: 26px 12px 12px 20px;
}


/* --- Sidebar content --------------------------------------------- */

aside {
	grid-area: aside;
	padding: 21px 12px 12px 20px;
	width: 300px;
}

#search_words {
	width: 122px;
}

#rss_icon {
	margin-left: 17px;
}

#rss_icon img {
	vertical-align: middle;
}

#sidebar_content {
	margin-top: 12px;
	font-size: $sidebar-font-size;
}

#sidebar_content p {
	line-height: 1.3em;
}

#sidebar_content .align_left, #sidebar_content .align_right {
	float: none;
}

#sidebar_content h2 {
	margin: 0 0 6px 0;
	padding: 0;
	font-size: $sidebar-h2-font-size;
}

#sidebar_content h3 {
	margin: 0 0 6px 0;
	padding: 0;
	font-size: $sidebar-h3-font-size;
}

#sidebar_content ul.events {
	margin: 0;
	padding: 0;
	list-style: none;
}

#sidebar_content .event.date {
	margin: 0 0 5px 0;
	padding: 0;
	color: $color-dark-gray;
	font-size: 0.8em;
}

/* --- Footer ------------------------------------------------------ */

footer {
	grid-area: footer;
	border-top: 1px solid $footer-border-color;
	padding: 2px 0;
	display: flex;
	flex-direction: row;
}

footer a {
	margin: 0 5px;
}

/* === Special templates =========================================== */

/* --- Home --- */

.page_type_home h1 {
	margin: 0;
	padding: 0;
	font-size: 160%;
}

.page_type_home p.date_time {
	margin: 2px 0;
	padding: 0;
}

.page_type_home p.read_on {
	margin-top: 0;
	padding: 0;
}

/* --- Events list --- */

.page_type_events #main_content h1 {
	border-bottom: 2px solid $color-light-blue;
	text-align: center;
	font-size: $default-font-size;
}

.page_type_events #main_content ul.events {
	margin: 0;
	padding: 0;
	list-style: none;
}
.page_type_events #main_content ul.events > li {
	border-bottom: 2px solid $color-light-blue;
}

.page_type_events #main_content .event {
	margin: 0;
	padding: 4px 7px;
}

/* --- Event --- */

.page_type_event #main_content .header {
	margin-bottom: 8px;
}

.page_type_event #main_content .header > * {
	margin: 0;
	padding: 0;
}

.page_type_event #main_content h1 {
	font-size: $event-h1-font-size;
}

.page_type_event #main_content .subtitle {
	margin: 0 0 20px 0;
	font-size: $event-subtitle-font-size;
}

.page_type_event #main_content .date_time {
	font-weight: bold;
}

.page_type_event #main_content .location {
	font-style: italic;
}

.page_type_event #main_content ul.speakers {
	margin: 21px 0 0px 0;
	list-style: none;
	font-weight: bold;
}

/* --- search --- */
.page_type_search .searchresults {
	li {
		margin-bottom: 1em;
	}
	p {
		margin-bottom: 0;
	}
	.datetime {
		font-size: 80%;
		margin-top: 0px;
	}
}


/* === Widgets ===================================================== */

ul.MarkupPagerNav {
	margin: 7px 0 0 36px;
	padding: 0;
	list-style: none;
	display: flex;
	flex-flow:row wrap;
	justify-content: left;
	align-content: left;
}

ul.MarkupPagerNav > li {
	margin: 0px 0px 7px 7px;
	padding: 7px 7px;
}

ul.MarkupPagerNav > li > a, ul.MarkupPagerNav > li > a:visited {
	color: $default-text-color;
	text-decoration: $link-text-decoration;
}
ul.MarkupPagerNav > li > a.active, ul.MarkupPagerNav > li > a:hover {
	color: $default-text-color;
	text-decoration: $link-hover-text-decoration;
}

ul.MarkupPagerNav > li.MarkupPagerNavOn > a {
	font-weight: bold;
}

/* === RichtText-Editor ============================================ */

// CKEditor

.content p {
	clear: both;
	margin: 0.6em 0 1.2em;
}

.content .align_left {
	float: left;
	margin: 0 $image-margin $image-margin 0;
	display: block;
}

.content .align_right {
	float: right;
	margin: 0 0 $image-margin $image-margin;
	display: block;
}

.content .align_center {
	margin: 0 auto $image-margin;
	display: block;
}

.content img {
	max-width: 100%;
}

/* === Accessibility helpers ======================================= */

/* Hide visually, but remain approachable for screenreader */

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	white-space: nowrap;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	border: 0;
}

/* Show bypass link on hover */

.element-focusable:focus {
	clip: auto;
	overflow: visible;
	height: auto;
}

/* Sample styling for bypass link */

.bypass-to-main:focus {
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	line-height: 40px;
	text-align: center;
	background: #333;
	color: #fff;
}


/* --- Include responsive styles ----------------------------------- */
@import 'responsive.scss';
/*@import 'lightbox.scss';*/