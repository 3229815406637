/** 
 * main.css
 * 
 * - Constants
 * - General HTML tags
 * - Helper classes
 * - Page layout
 * - RichText-Editor
 * - Accessibility helpers
 * - Responsive-related stuff (imported)
 * 
 */
/* === Constants =================================================== */
/* === General HTML tags =========================================== */
* {
  box-sizing: border-box; }

html {
  height: 100%; }

body {
  margin: 0 auto;
  padding: 0;
  height: 100%;
  background-color: #edeceb;
  background-image: url("../img//background-header.png");
  background-repeat: repeat-x;
  color: #000000;
  font-family: Verdana, "Lucida Grande", Arial, sans-serif;
  font-size: 14px; }

p, ul, ol, li, table {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.5em; }

h1, h2, h3, h4, h5, h6 {
  padding-top: 0;
  padding-bottom: 0; }

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0; }

h1 {
  padding-bottom: 6px;
  font-family: Verdana, "Lucida Grande", Arial, sans-serif;
  font-size: 2em;
  font-weight: normal;
  color: #000000; }

h2 {
  font-family: Verdana, "Lucida Grande", Arial, sans-serif;
  font-size: 1.17em;
  font-weight: normal;
  color: #000000; }

h3 {
  font-family: Verdana, "Lucida Grande", Arial, sans-serif;
  font-size: 1em;
  font-weight: normal;
  color: #000000; }

a img {
  border: 0; }

a, a:visited {
  color: #00A4CD;
  text-decoration: none;
  font-weight: normal; }

a:hover, a:active {
  color: #000000;
  text-decoration: underline; }

pre {
  font-size: 9.8px;
  line-height: 1.2; }

input[type=text], input[type=password], textarea {
  color: #494949;
  font-family: Verdana, "Lucida Grande", Arial, sans-serif; }

input, button, select, option, .button {
  color: #494949;
  font-family: Verdana, "Lucida Grande", Arial, sans-serif; }

input[type=submit], input[type=button], button, .button {
  padding: 0; }

/* === Helper classes ============================================== */
/* Ensured the container is at least as high as
 * any floats it might contain.
 */
.container {
  overflow: hidden; }

.container:after {
  content: "";
  display: table;
  clear: both; }

/* === Page layout ================================================= */
#overlay {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  z-index: 500;
  background-color: rgba(1, 1, 1, 0.75);
  left: 0; }

#page_wrapper {
  min-height: 100vh; }

#page {
  margin: 0 auto 0;
  width: 1005px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas: "header header" "topnav topnav"  "main   aside" "footer footer"; }

/* --- Header ------------------------------------------------------ */
header {
  padding: 23px 12px 0 20px;
  height: 153px;
  grid-area: header;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: min-content 1fr;
  grid-template-areas: "logo title  hamburger" "logo slogan hamburger"; }

header a, header a:visited {
  color: #000000;
  text-decoration: none; }

header a:hover, header a:active {
  color: #000000;
  text-decoration: underline; }

#logo {
  grid-area: logo;
  margin-left: 2px;
  margin-right: 33px; }

#title {
  grid-area: title;
  margin-top: 3px;
  font-size: 45px;
  color: #000000; }

#slogan {
  grid-area: slogan;
  margin-top: 3px;
  font-size: 14px;
  color: #000000; }

#hamburger {
  grid-area: hamburger; }

/* --- Top menu ---------------------------------------------------- */
#topnav {
  grid-area: topnav;
  padding: 10px 0 0 18px;
  height: 40px;
  font-size: 1.3em; }

#topnav ul {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  list-style: none; }

#topnav li {
  margin-right: 26px; }

#topnav li a, #topnav li a:visited {
  color: #ff7900;
  font-weight: normal;
  text-decoration: none; }

#topnav li a:hover, #topnav li a:active {
  color: #ff7900;
  text-decoration: underline; }

#topnav li.current a, #topnav li.current a:visited {
  color: #000000;
  font-weight: normal;
  text-decoration: none; }

#topnav li.current a:hover, #topnav li.current a:active {
  color: #000000;
  text-decoration: underline; }

/* --- Main content ------------------------------------------------ */
main {
  grid-area: main;
  padding: 26px 12px 12px 20px; }

/* --- Sidebar content --------------------------------------------- */
aside {
  grid-area: aside;
  padding: 21px 12px 12px 20px;
  width: 300px; }

#search_words {
  width: 122px; }

#rss_icon {
  margin-left: 17px; }

#rss_icon img {
  vertical-align: middle; }

#sidebar_content {
  margin-top: 12px;
  font-size: 13px; }

#sidebar_content p {
  line-height: 1.3em; }

#sidebar_content .align_left, #sidebar_content .align_right {
  float: none; }

#sidebar_content h2 {
  margin: 0 0 6px 0;
  padding: 0;
  font-size: 15px; }

#sidebar_content h3 {
  margin: 0 0 6px 0;
  padding: 0;
  font-size: 15px; }

#sidebar_content ul.events {
  margin: 0;
  padding: 0;
  list-style: none; }

#sidebar_content .event.date {
  margin: 0 0 5px 0;
  padding: 0;
  color: #666666;
  font-size: 0.8em; }

/* --- Footer ------------------------------------------------------ */
footer {
  grid-area: footer;
  border-top: 1px solid #999999;
  padding: 2px 0;
  display: flex;
  flex-direction: row; }

footer a {
  margin: 0 5px; }

/* === Special templates =========================================== */
/* --- Home --- */
.page_type_home h1 {
  margin: 0;
  padding: 0;
  font-size: 160%; }

.page_type_home p.date_time {
  margin: 2px 0;
  padding: 0; }

.page_type_home p.read_on {
  margin-top: 0;
  padding: 0; }

/* --- Events list --- */
.page_type_events #main_content h1 {
  border-bottom: 2px solid #d3e7f4;
  text-align: center;
  font-size: 14px; }

.page_type_events #main_content ul.events {
  margin: 0;
  padding: 0;
  list-style: none; }

.page_type_events #main_content ul.events > li {
  border-bottom: 2px solid #d3e7f4; }

.page_type_events #main_content .event {
  margin: 0;
  padding: 4px 7px; }

/* --- Event --- */
.page_type_event #main_content .header {
  margin-bottom: 8px; }

.page_type_event #main_content .header > * {
  margin: 0;
  padding: 0; }

.page_type_event #main_content h1 {
  font-size: 160%; }

.page_type_event #main_content .subtitle {
  margin: 0 0 20px 0;
  font-size: 1.17em; }

.page_type_event #main_content .date_time {
  font-weight: bold; }

.page_type_event #main_content .location {
  font-style: italic; }

.page_type_event #main_content ul.speakers {
  margin: 21px 0 0px 0;
  list-style: none;
  font-weight: bold; }

/* --- search --- */
.page_type_search .searchresults li {
  margin-bottom: 1em; }

.page_type_search .searchresults p {
  margin-bottom: 0; }

.page_type_search .searchresults .datetime {
  font-size: 80%;
  margin-top: 0px; }

/* === Widgets ===================================================== */
ul.MarkupPagerNav {
  margin: 7px 0 0 36px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-content: left; }

ul.MarkupPagerNav > li {
  margin: 0px 0px 7px 7px;
  padding: 7px 7px; }

ul.MarkupPagerNav > li > a, ul.MarkupPagerNav > li > a:visited {
  color: #000000;
  text-decoration: none; }

ul.MarkupPagerNav > li > a.active, ul.MarkupPagerNav > li > a:hover {
  color: #000000;
  text-decoration: underline; }

ul.MarkupPagerNav > li.MarkupPagerNavOn > a {
  font-weight: bold; }

/* === RichtText-Editor ============================================ */
.content p {
  clear: both;
  margin: 0.6em 0 1.2em; }

.content .align_left {
  float: left;
  margin: 0 16px 16px 0;
  display: block; }

.content .align_right {
  float: right;
  margin: 0 0 16px 16px;
  display: block; }

.content .align_center {
  margin: 0 auto 16px;
  display: block; }

.content img {
  max-width: 100%; }

/* === Accessibility helpers ======================================= */
/* Hide visually, but remain approachable for screenreader */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  white-space: nowrap;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0; }

/* Show bypass link on hover */
.element-focusable:focus {
  clip: auto;
  overflow: visible;
  height: auto; }

/* Sample styling for bypass link */
.bypass-to-main:focus {
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #333;
  color: #fff; }

/* --- Include responsive styles ----------------------------------- */
/* === Constants =================================================== */
/* === Mobile menu ================================================= */
/* --- Hamburger --------------------------------------------------- */
#hamburger {
  justify-self: flex-end;
  margin: 8px 24px;
  padding: 8px;
  width: 48px;
  height: 48px;
  border-radius: 8px; }

#hamburger > ul {
  display: none; }

#hamburger div {
  height: 20%;
  background-color: #ff7900; }

#hamburger div:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

#hamburger div:nth-child(2) {
  margin: 20% 0; }

#hamburger div:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

/* --- Drawer menu ------------------------------------------------- */
.drawer {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  width: 0;
  max-width: 480px; }

.left.drawer {
  left: 0; }

.right.drawer {
  right: 0; }

.drawer * {
  box-sizing: border-box; }

.drawer_opened .drawer {
  min-width: 240px;
  width: 75vw; }

/* --- Mobile menu pages --- */
#drawer_menu {
  position: fixed;
  top: 0;
  height: 100%;
  background-color: #ffffff;
  transition: width 300ms ease-out;
  overflow-x: hidden;
  overflow-y: auto; }

#drawer_menu.left {
  left: -1px;
  border-right: 1px solid #edeceb; }

#drawer_menu.right {
  right: 1px;
  border-left: 1px solid #999999; }

#drawer_menu .menu_page {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  top: 0;
  background-color: #ffffff; }

#drawer_menu.left .menu_page {
  left: -100%;
  border-left: 1px solid #edeceb; }

#drawer_menu.right .menu_page {
  right: -100%;
  border-right: 1px solid #edeceb; }

.animate #drawer_menu.left .menu_page {
  transition: left 300ms ease-out; }

.animate #drawer_menu.right .menu_page {
  transition: right 300ms ease-out; }

#drawer_menu .menu_page.above {
  left: -100%;
  z-index: 99; }

#drawer_menu .menu_page.focussed {
  left: 0;
  z-index: 100; }

#drawer_menu .menu_page.below {
  left: 100%;
  z-index: 99; }

#drawer_menu .menu_page li {
  clear: both;
  margin: 0;
  border-bottom: 1px solid #edeceb; }

#drawer_menu .menu_page li.active {
  background-color: #ffffff; }

#drawer_menu .menu_page a {
  display: block;
  padding: 12px; }

#drawer_menu .menu_page .close {
  float: right;
  margin: 0;
  border-left: 1px solid #edeceb;
  width: 3em;
  text-align: center; }

#drawer_menu .menu_page .back {
  float: left;
  margin: 0;
  width: 3em;
  border-right: 1px solid #edeceb;
  text-align: center; }

#drawer_menu .menu_page .current {
  display: block;
  padding-left: 3.5em;
  font-weight: bold; }

#drawer_menu .menu_page .child {
  display: block;
  padding-left: 3.5em; }

#drawer_menu .menu_page .more {
  float: right;
  margin: 0;
  border-left: 1px solid #edeceb;
  width: 3em;
  text-align: center; }

#drawer_menu li a, #drawer_menu li a:visited {
  color: #ff7900;
  font-weight: bold;
  text-decoration: none; }

#drawer_menu li a:hover, #drawer_menu li a:active {
  color: #ff7900;
  text-decoration: underline; }

#drawer_menu li.active a, #drawer_menu li.active a:visited {
  color: #000000;
  font-weight: bold;
  text-decoration: none; }

#drawer_menu li.active a:hover, #drawer_menu li.active a:active {
  color: #000000;
  text-decoration: underline; }

/* --- Page content wrapper --- */
#page_wrapper {
  position: relative;
  min-height: 100vh;
  transform: 0;
  transition: transform 300ms ease-out;
  z-index: 200; }

/* --- Overlay --- */
.drawer_opened #overlay {
  display: block;
  background-color: rgba(1, 1, 1, 0); }

/* === Collapsible content ======================================== */
input.collapsible_toggle {
  display: none; }

label.collapsible_toggle {
  display: none;
  padding: 4px 8px; }

label.collapsible_toggle span {
  display: block;
  font-size: 16px;
  font-weight: bold; }

label.collapsible_toggle span::before {
  display: inline-block;
  margin: 0 0.5em 0 0.25em;
  content: "\25b6";
  transform: translateY(-0.2em);
  transition: transform 300ms ease-out; }

input.collapsible_toggle:checked + label.collapsible_toggle > span::before {
  transform: rotate(90deg); }

label.collapsible_toggle + .collapsible {
  overflow: hidden;
  transition: max-height 400ms ease-in-out; }

input.collapsible_toggle:checked + label.collapsible_toggle + .collapsible {
  max-height: 1000px; }

label.collapsible_toggle {
  margin: 0;
  border-bottom: 1px solid #edeceb;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 1.5em; }

input.collapsible_toggle:checked + label.collapsible_toggle {
  border-radius: 4px 4px 0 0; }

/* === Media sizes ================================================= */
/* --- Large devices ----------------------------------------------- */
@media only screen and (min-width: 1024px) {
  .only_desktop, .not_mobile {
    display: block; }
  .not_desktop, .only_mobile {
    display: none; }
  .only_tablet {
    display: none; }
  .not_tablet {
    display: block; }
  .only_phone {
    display: none; }
  .not_phone {
    display: block; } }

/* --- Large and medium devices ------------------------------------ */
/* --- Medium devices ---------------------------------------------- */
@media only screen and (min-width: 640px) and (max-width: 1023px) {
  .only_desktop, .not_mobile {
    display: none; }
  .not_desktop, .only_mobile {
    display: block; }
  .only_tablet {
    display: block; }
  .not_tablet {
    display: none; }
  .only_phone {
    display: none; }
  .not_phone {
    display: block; } }

/* --- Medium and small devices ------------------------------------ */
@media only screen and (max-width: 1023px) {
  body {
    overflow-x: hidden; }
  input, select, option, button, input[type=file] + label {
    font-size: 16px;
    height: 32px; }
  input[type=submit], input[type=button], button, .button {
    padding: 2px 6px; }
  textarea {
    font-size: 16px; }
  #page {
    width: auto;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto 1fr auto;
    grid-template-areas: "header" "drawer"  "aside" "main" "footer"; }
  header {
    padding: 23px 20px 0 20px; }
  #search_words {
    float: left;
    margin-left: 0;
    width: 62%; }
  #search_submit_button {
    float: left;
    margin-left: 4px; }
  #rss_icon {
    float: right;
    margin: 4px 0 0 0; }
  main {
    padding: 0 20px 20px; }
  /* --- Make sidebar collapsible --- */
  label.collapsible_toggle {
    display: block; }
  label.collapsible_toggle + .collapsible {
    max-height: 0; }
  aside {
    padding: 20px;
    width: 100%; }
  #sidebar {
    width: 100%;
    height: auto;
    background-color: #ffffff; }
  #search_form {
    margin: 20px 20px 20px; }
  #sidebar_content {
    margin: 0 20px 20px; } }

/* --- Small devices ----------------------------------------------- */
@media only screen and (max-width: 639px) {
  .only_desktop, .not_mobile {
    display: none; }
  .not_desktop, .only_mobile {
    display: block; }
  .only_tablet {
    display: none; }
  .not_tablet {
    display: block; }
  .only_phone {
    display: block; }
  .not_phone {
    display: none; }
  body {
    font-size: 16px; }
  header {
    padding: 23px 8px 0 8px; }
  #title {
    font-size: 24px; }
  main {
    padding: 0 8px 8px; }
  /* --- Make sidebar collapsible --- */
  aside {
    padding: 8px 8px 20px;
    width: 100%; }
  #sidebar {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border-radius: 0 0 4px 4px; }
  #search_form {
    margin: 20px 20px 20px; }
  #sidebar_content {
    margin: 0 20px 20px; }
  #main_content img, #sidebar_content img {
    width: 100%; }
  /* --- Sidebar content --- */
  #sidebar_content {
    font-size: 16px; }
  #sidebar_content h2 {
    font-size: 16px; }
  #sidebar_content h3 {
    font-size: 16px; }
  #sidebar_content ul.events {
    list-style: none; }
  #sidebar_content .event.date {
    font-size: 16px; }
  /* --- Special templates --- */
  .page_type_events #main_content h1.year {
    font-size: 16px; }
  .page_type_event #main_content .details img {
    width: auto;
    min-width: 50%;
    max-width: 100%; }
  /* --- Widgets --- */
  ul.MarkupPagerNav > li {
    border-radius: 4px;
    padding: 7px 14px;
    background-color: #ffffff; }
  ul.MarkupPagerNav > li.MarkupPagerNavSeparator {
    background-color: transparent; } }

/*@import 'lightbox.scss';*/
