/* === Constants =================================================== */

$phone-text-size: 16px;

$phone-title-font-size: 24px;

$hamburger-color: $color-orange;

$drawer-link-font-weight: bold;
$drawer-link-color: $color-orange;
$drawer-link-text-decoration: none;
$drawer-link-hover-color: $color-orange;
$drawer-link-hover-text-decoration: underline;
$drawer-current-link-font-weight: bold;
$drawer-current-link-color: $color-black;
$drawer-current-link-text-decoration: none;
$drawer-current-link-hover-color: $color-black;
$drawer-current-link-hover-text-decoration: underline;

$sidebar-color: $color-white;


/* === Mobile menu ================================================= */

/* --- Hamburger --------------------------------------------------- */

#hamburger {
    justify-self: flex-end;
    margin: 8px 24px;
    padding: 8px;
    width: 48px;
    height: 48px;
    border-radius: 8px;
}

#hamburger > ul {
    display: none;
}

#hamburger div {
    height: 20%;
    background-color: $hamburger-color;
}

#hamburger div:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

#hamburger div:nth-child(2) {
    margin: 20% 0;
}

#hamburger div:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}


/* --- Drawer menu ------------------------------------------------- */

.drawer {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    width: 0;
    max-width: 480px;
}
.left.drawer {
    left: 0;
}
.right.drawer {
    right: 0;
}

.drawer * {
    box-sizing: border-box;
}

.drawer_opened .drawer {
    min-width: 240px;
    width: 75vw;
}

/* --- Mobile menu pages --- */
#drawer_menu {
    position: fixed;
    top: 0;
    height: 100%;
    background-color: $color-white;
    transition: width 300ms ease-out;
    overflow-x: hidden;
    overflow-y: auto;
}
#drawer_menu.left {
    left: -1px;
    border-right: 1px solid $color-light-gray;
}
#drawer_menu.right {
    right: 1px;
    border-left: 1px solid $color-medium-gray;
}

#drawer_menu .menu_page {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    top: 0;
    background-color: $color-white;
}
#drawer_menu.left .menu_page {
    left: -100%;
    border-left: 1px solid $color-light-gray;
}
#drawer_menu.right .menu_page {
    right: -100%;
    border-right: 1px solid $color-light-gray;
}

.animate #drawer_menu.left .menu_page {
    transition: left 300ms ease-out;
}
.animate #drawer_menu.right .menu_page {
    transition: right 300ms ease-out;
}

#drawer_menu .menu_page.above {
    left: -100%;
    z-index: 99;
}

#drawer_menu .menu_page.focussed {
    left: 0;
    z-index: 100;
}

#drawer_menu .menu_page.below {
    left: 100%;
    z-index: 99;
}

#drawer_menu .menu_page li {
    clear: both;
    margin: 0;
    border-bottom: 1px solid $color-light-gray;
}

#drawer_menu .menu_page li.active {
    background-color: $color-white;
}

#drawer_menu .menu_page a {
    display: block;
    padding: 12px;
}

#drawer_menu .menu_page .close {
    float: right;
    margin: 0;
    border-left: 1px solid $color-light-gray;
    width: 3em;
    text-align: center;
}

#drawer_menu .menu_page .back {
    float: left;
    margin: 0;
    width: 3em;
    border-right: 1px solid $color-light-gray;
    text-align: center;
}

#drawer_menu .menu_page .current {
    display: block;
    padding-left: 3.5em;
    font-weight: bold;
}

#drawer_menu .menu_page .child {
    display: block;
    padding-left: 3.5em;
}

#drawer_menu .menu_page .more {
    float: right;
    margin: 0;
    border-left: 1px solid $color-light-gray;
    width: 3em;
    text-align: center;
}

#drawer_menu li a, #drawer_menu li a:visited {
	color: $drawer-link-color;
	font-weight: $drawer-link-font-weight;
	text-decoration: $drawer-link-text-decoration;
}
#drawer_menu li a:hover, #drawer_menu li a:active {
	color: $drawer-link-hover-color;
	text-decoration: $drawer-link-hover-text-decoration;
}
#drawer_menu li.active a, #drawer_menu li.active a:visited {
	color: $drawer-current-link-color;
	font-weight: $drawer-current-link-font-weight;
	text-decoration: $drawer-current-link-text-decoration;
}
#drawer_menu li.active a:hover, #drawer_menu li.active a:active {
	color: $drawer-current-link-hover-color;
	text-decoration: $drawer-current-link-hover-text-decoration;
}


/* --- Page content wrapper --- */
#page_wrapper {
    position: relative;
    min-height: 100vh;
    transform: 0;
    transition: transform 300ms ease-out;
    // overflow-x: hidden; <-- wird jetzt im JS beim Ausklappen des Menues gesetzt, weil es das Anspringen von Anchors in FF verhindert.
    z-index: 200;
}

/* --- Overlay --- */
.drawer_opened #overlay {
    display: block;
    background-color: rgba(1, 1, 1, 0);
}

/* === Collapsible content ======================================== */

input.collapsible_toggle {
    display: none;
}

label.collapsible_toggle {
    display: none;
    padding: 4px 8px;
}

label.collapsible_toggle span {
    display: block;
    font-size: $phone-text-size;
    font-weight: bold;
}

label.collapsible_toggle span::before {
    display: inline-block;
    margin: 0 0.5em 0 0.25em;
    content: "\25b6";
    transform: translateY(-0.2em);
    transition: transform 300ms ease-out;
}

input.collapsible_toggle:checked+label.collapsible_toggle>span::before {
    transform: rotate(90deg);
}

label.collapsible_toggle+.collapsible {
    overflow: hidden;
    transition: max-height 400ms ease-in-out;
}

input.collapsible_toggle:checked+label.collapsible_toggle+.collapsible {
    max-height: 1000px;
}

label.collapsible_toggle {
    margin: 0;
    border-bottom: 1px solid $color-light-gray;
    border-radius: 4px;
    background-color: #ffffff;
    font-size: 1.5em;
}

input.collapsible_toggle:checked+label.collapsible_toggle {
    border-radius: 4px 4px 0 0;
}

/* === Media sizes ================================================= */

/* --- Large devices ----------------------------------------------- */
@media only screen and (min-width: 1024px) {
    .only_desktop, .not_mobile {
        display: block;
    }
    .not_desktop, .only_mobile {
        display: none;
    }
    .only_tablet {
        display: none;
    }
    .not_tablet {
        display: block;
    }
    .only_phone {
        display: none;
    }
    .not_phone {
        display: block;
    }
}

/* --- Large and medium devices ------------------------------------ */
@media only screen and (min-width: 640px) {
}

/* --- Medium devices ---------------------------------------------- */
@media only screen and (min-width: 640px) and (max-width: 1023px) {
    .only_desktop, .not_mobile {
        display: none;
    }
    .not_desktop, .only_mobile {
        display: block;
    }
    .only_tablet {
        display: block;
    }
    .not_tablet {
        display: none;
    }
    .only_phone {
        display: none;
    }
    .not_phone {
        display: block;
    }
}

/* --- Medium and small devices ------------------------------------ */
@media only screen and (max-width: 1023px) {
    body {
        overflow-x: hidden;
    }
    
    input, select, option, button, input[type=file] + label {
        font-size: $phone-text-size;
        height: 32px;
    }

    input[type=submit], input[type=button], button, .button {
        padding: 2px 6px;
    }
    
    textarea {
        font-size: $phone-text-size;
    }

    #page {
        width: auto;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto 1fr auto;
        grid-template-areas:
            "header"
            "drawer" 
            "aside"
            "main"
            "footer";
    }

    header {
        padding: 23px 20px 0 20px;
    }

    #search_words {
        float: left;
        margin-left: 0;
        width: 62%;
    }

    #search_submit_button {
        float: left;
        margin-left: 4px;
    }

    #rss_icon {
        float: right;
        margin: 4px 0 0 0;
    }

    main {
        padding: 0 20px 20px;
    }

    /* --- Make sidebar collapsible --- */
    label.collapsible_toggle {
        display: block;
    }
    label.collapsible_toggle+.collapsible {
        max-height: 0;
    }

    aside {
        padding: 20px;
        width: 100%;
    }

    #sidebar {
        width: 100%;
        height: auto;
        background-color: $color-white;
    }

    #search_form {
        margin: 20px 20px 20px;
    }

    #sidebar_content {
        margin: 0 20px 20px;
    }

}

/* --- Small devices ----------------------------------------------- */
@media only screen and (max-width: 639px) {
    .only_desktop, .not_mobile {
        display: none;
    }
    .not_desktop, .only_mobile {
        display: block;
    }
    .only_tablet {
        display: none;
    }
    .not_tablet {
        display: block;
    }
    .only_phone {
        display: block;
    }
    .not_phone {
        display: none;
    }

    body {
        font-size: $phone-text-size;
    }

    header {
        padding: 23px 8px 0 8px;
    }

    #title {
        font-size: $phone-title-font-size;
    }

    main {
        padding: 0 8px 8px;
    }

    /* --- Make sidebar collapsible --- */
    aside {
        padding: 8px 8px 20px;
        width: 100%;
    }

    #sidebar {
        width: 100%;
        height: auto;
        background-color: $color-white;
        border-radius: 0 0 4px 4px;
    }

    #search_form {
        margin: 20px 20px 20px;
    }

    #sidebar_content {
        margin: 0 20px 20px;
    }

    #main_content img, #sidebar_content img {
        width: 100%;
    }

    /* --- Sidebar content --- */

    #sidebar_content {
        font-size: $phone-text-size;
    }

    #sidebar_content h2 {
        font-size: $phone-text-size;
    }
    
    #sidebar_content h3 {
        font-size: $phone-text-size;
    }
    
    #sidebar_content ul.events {
        list-style: none;
    }
    
    #sidebar_content .event.date {
        font-size: $phone-text-size;
    }

    /* --- Special templates --- */

    .page_type_events #main_content h1.year {
        font-size: $phone-text-size;
    }

    .page_type_event #main_content .details img {
        width: auto;
        min-width: 50%;
        max-width: 100%;
    }
    
    /* --- Widgets --- */

    ul.MarkupPagerNav > li {
        border-radius: 4px;
        padding: 7px 14px;
        background-color: $color-white;
    }

    ul.MarkupPagerNav > li.MarkupPagerNavSeparator {
        background-color: transparent;
    }
    
}
